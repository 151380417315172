import React, { useEffect, useState } from 'react';
import { CButton, CForm, CFormInput, CFormLabel } from '@coreui/react';
import Select from 'react-select';
import AxiosInstance from '../Axios';

// Компонент для рудагування поля
const UpdateFormField = (props) => {
    // console.log('props', props);

    const urlApi = '/form_fields';

    // Типи полів
    const types = [
        { value: "text", label: "Текстова стрічка" },
        { value: "textarea", label: "Текст" },
        { value: "number", label: "Число" },
        { value: "tel", label: "Телефон" },
        { value: "date", label: "Дата" },
        { value: "email", label: "Емейл" },
        { value: "password", label: "Пароль" },
        { value: "file", label: "Файл" },
    ];

    // Значення інпута (тільки для відображення)
    const [inputValue, setInputValue] = useState(
        (props !== undefined) ? { type: { value: props.formField.type, label: props.formField.selectedLabelInput } } : ""
    );
    // Параметри поля
    const [input, setInput] = useState({
        id: (props !== undefined) ? props.formField.id : "",
        type: (props !== undefined) ? props.formField.type : "",
        selectedLabelInput: (props !== undefined) ? props.formField.selectedLabelInput : "",
        label: (props !== undefined) ? props.formField.label : "",
        name: (props !== undefined) ? props.formField.name : "",
        defaultValue: (props !== undefined) ? props.formField.defaultValue : "",
        form: (props !== undefined) ? props.formField.form : "",
        required: (props.formField?.required !== undefined) ? props.formField.required : false,
        deleted: (props.formField?.deleted !== undefined) ? props.formField.deleted : false,
    });

    // useEffect(() => {
    //     console.log('input', input);
    // }, [input]);

    // Функція для оновлення поля форми
    const handleSubmit = event => {
        event.preventDefault();

        AxiosInstance.put(`${urlApi}/${props.formField.id}`, input).then((response) => {
            // console.log('post response', response);
            if (response.status == 200) {
                alert("Поле оновлено");
                // Закрити модальне вікно оновлення поля форми
                props.handleClose();
                // Оновити форму
                props.getFormsData();
            } else {
                alert("Error");
            };
        });
    };

    // Функція для змінити значення полів ("Назва поля" та "name")
    const handleChange = (value, name) => {
        setInput({
            ...input,
            [name]: value
        });
    };
    // Функція для змінити значення поля ("Тип поля")
    const handleChange_1 = (e) => {
        // console.log('e', e);
        // Для наповнення даних для відправлення на сервер
        setInput({
            ...input,
            type: e.value,
            selectedLabelInput: e.label
        });

        // Для коректного відображення вибраного значення інпута
        setInputValue({ type: e });
    };

    // Скинути зміни
    const reset = () => {
        setInput({
            id: (props !== undefined) ? props.formField.id : "",
            type: (props !== undefined) ? props.formField.type : "",
            selectedLabelInput: (props !== undefined) ? props.formField.selectedLabelInput : "",
            label: (props !== undefined) ? props.formField.label : "",
            name: (props !== undefined) ? props.formField.name : "",
            defaultValue: (props !== undefined) ? props.formField.defaultValue : "",
            form: (props !== undefined) ? props.formField.form : "",
            required: (props.formField?.required !== undefined) ? props.formField.required : false,
            deleted: (props.formField?.deleted !== undefined) ? props.formField.deleted : false,
        });
        setInputValue(
            (props !== undefined) ? { type: { value: props.formField.type, label: props.formField.selectedLabelInput } } : ""
        );
    };

    return (
        <CForm onSubmit={handleSubmit}>
            <div className="row d-flex mt-3">

                <div className="col-6 mb-3">
                    <CFormLabel>Тип поля</CFormLabel>
                    <Select placeholder="Тип поля" options={types}
                        value={inputValue.type}
                        onChange={(e) => { handleChange_1(e) }} required />
                </div>
                <div className="col-6 mb-3">
                    <CFormInput type="text" id="exampleFormControlInput2" placeholder="Початкове значення"
                        label="Початкове значення"
                        value={input.defaultValue} onChange={(e) => { handleChange(e.target.value, 'defaultValue') }} required
                    />
                </div>
                <div className="col-6 mb-3">
                    <CFormInput type="text" id="exampleFormControlInput2" placeholder="Назва поля"
                        label="Назва поля"
                        value={input.label} onChange={(e) => { handleChange(e.target.value, 'label') }} required
                    />
                </div>
                <div className="col-6 mb-3">
                    <CFormInput type="text" id="exampleFormControlInput2" placeholder="name" label="name поля"
                        value={input.name} onChange={(e) => { handleChange(e.target.value, 'name') }} required
                    />
                </div>

                <div className="col-6 mb-3" style={{textAlign: "left", display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        value={input.required} 
                        onChange={() => { handleChange(!input.required, 'required')}} 
                        id="flexCheckDefault1" checked = {input.required}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault1">&nbsp;Обов'язкове поле</label>
                </div>
                <div className="col-6 mb-3" style={{textAlign: "left", display: 'flex', gap: '10px', alignItems: 'center'}}>
                    <input 
                        className="form-check-input" 
                        type="checkbox" 
                        value={input.deleted} 
                        onChange={() => { handleChange(!input.deleted, 'deleted')}} 
                        id="flexCheckDefault2" checked = {input.deleted}
                    />
                    <label className="form-check-label" htmlFor="flexCheckDefault2">&nbsp;Приховати поле</label>
                </div>

                <div className="col-2 mt-3">
                    <CButton type="submit" className="mb-2" color="success">Зберегти</CButton>
                </div>
                <div className="col-2 mt-3">
                    <CButton className="mb-2" color="secondary" onClick={() => reset()}>Скинути</CButton>
                </div>
            </div>
        </CForm>
    );
};

export default UpdateFormField;