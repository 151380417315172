import React, { useEffect, useRef, useState } from 'react';
import { CButton, CForm, CFormInput, CFormLabel } from '@coreui/react';
import Select from 'react-select';
import AxiosInstance from '../Axios';

// Компонент для додавання нових полів (типи для інпутів та їх лейбли)
const AddInputType = (props) => {
    // console.log('props dataId', props);

    const urlApi = '/form_fields';
    const selectInputRef = useRef();

    // Типи полів
    const types = [
        { value: "text", label: "Текстова стрічка" },
        { value: "textarea", label: "Текст" },
        { value: "number", label: "Число" },
        { value: "tel", label: "Телефон" },
        { value: "date", label: "Дата" },
        { value: "email", label: "Емейл" },
        { value: "password", label: "Пароль" },
        { value: "file", label: "Файл" },
    ];

    // Значення інпута (тільки для відображення)
    const [inputValue, setInputValue] = useState({});
    // Параметри нового поля
    const [newInput, setNewInput] = useState({
        type: "",
        selectedLabelInput: "",
        label: "",
        name: "",
        defaultValue: "",
        form: "",
        deleted: false,
        required: false
    });

    // Додати id форми
    useEffect(() => {
        if (props.formId !== undefined) {
            // console.log('props.formId', props.formId);
            setNewInput({
                ...newInput,
                form: `api/forms/${props.formId}`
            });
        }
    }, [props.formId])

    // useEffect(() => {
    //     console.log('newInput', newInput);
    // }, [newInput]);

    // Функція для додавання нового поля до форми
    const handleSubmit = event => {
        event.preventDefault();
        // console.log('Поле додано');

        AxiosInstance.post(urlApi, newInput).then((response) => {
            // console.log('post response', response);
            if (response.status == 201) {
                alert("Поле додано");
                // Очистити поля 
                setNewInput({ ...newInput, type: "", selectedLabelInput: "", label: "", name: "", defaultValue: "", required: false });
                setInputValue(selectInputRef.current.props.placeholder);
                // Оновити форму
                props.getFormsData();
            } else {
                alert("Error");
            }
        });
    };

    // Функція для змінити значення полів ("Назва поля" та "name")
    const handleChange = (value, name) => {
        console.log(value);

        setNewInput({
            ...newInput,
            [name]: value
        });
    };
    // Функція для змінити значення поля ("Тип поля")
    const handleChange_1 = (e) => {
        // Для наповнення даних для відправлення на сервер
        setNewInput({
            ...newInput,
            type: e.value,
            selectedLabelInput: e.label
        });

        // Для коректного відображення вибраного значення інпута
        setInputValue({ type: e });
    };

    return (
        <>
            <h4>Додати нове поле</h4>
            <CForm onSubmit={handleSubmit}>
                <div className="row d-flex mt-3 align-items-start">
                    <div className="col-1">
                        <CButton type="submit" className="mb-2" color="secondary">+</CButton>
                    </div>
                    <div className="col-2">
                        <Select placeholder="Тип поля" options={types}
                            ref={selectInputRef}
                            value={inputValue.type || ''}
                            onChange={(e) => { handleChange_1(e) }} required />
                    </div>
                    <div className="col-3">
                        <CFormInput type="text" id="exampleFormControlInput2" placeholder="Початкове значення"
                            value={newInput.defaultValue} onChange={(e) => { handleChange(e.target.value, 'defaultValue') }} required
                        />
                    </div>
                    <div className="col-2">
                        <CFormInput type="text" id="exampleFormControlInput2" placeholder="Назва поля"
                            value={newInput.label} onChange={(e) => { handleChange(e.target.value, 'label') }} required
                        />
                    </div>
                    <div className="col-2">
                        <CFormInput type="text" id="exampleFormControlInput2" placeholder="name"
                            value={newInput.name} onChange={(e) => { handleChange(e.target.value, 'name') }} required
                        />
                    </div>
                    <div className="col-2" style={{textAlign: "left", display: 'flex', gap: '10px', alignItems: 'center'}}>
                        <input 
                            className="form-check-input" 
                            type="checkbox" 
                            value={newInput.required} 
                            onChange={() => { handleChange(!newInput.required, 'required')}} 
                            id="flexCheckDefault" checked = {newInput.required}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">&nbsp;Обов'язкове поле</label>
                    </div>
                </div>
            </CForm >
        </>
    );
};

export default AddInputType;