/* eslint-disable prettier/prettier */
import React, { useRef, useState } from 'react';
import {
    CForm,
    CFormLabel,
    CFormInput,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow
  } from '@coreui/react';

import {Link, useLoaderData} from 'react-router-dom';
import axios from "axios";
import { DataGrid } from '@mui/x-data-grid';
// import { Button } from '@coreui/coreui';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {
  cilPen,
  cilPlus,
  cilTrash,
  cilCopy
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';


const tokenStr = localStorage.getItem('user');
const header = { headers: {"Authorization" : `Bearer ${tokenStr}`} };

export async function LoaderMedia() {
  let res = await  axios.get(process.env.REACT_APP_SERVER_URL + `/api/media_objects?pagination=false`, header).then((response) => {
    return response;
  });
  return res;
}

const Media  = () => {
  // eslint-disable-next-line prettier/prettier
  const {data}  = useLoaderData();
  const [rows, setRows] = useState(false);
  const [file, setFile] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);

  function fiterRows(dataRows){
    const regex = /\.(jpg|png|gif|webp|mov|avi|mp4|mkv|svg)\b/i;
    return dataRows.filter(item => {
        return regex.test(item.contentUrl); 
    })
  }

  function UpdateAll() {
    axios.get(process.env.REACT_APP_SERVER_URL + `/api/media_objects?pagination=false`, header).then((response) => {
      const sortable = fiterRows(response.data['hydra:member'].flat()).sort(function(prev, next) {
        return next.id - prev.id;
      });
      setRows(sortable);
      setRowCountState(sortable.length);
    });
  }
  
  if(!rows){
    UpdateAll();
  }

  function handleChange(event) {
    setFile(event.target.files[0])
  }

    const handleSubmit = event => {
        event.preventDefault()
        const url = process.env.REACT_APP_SERVER_URL + '/api/media_objects';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            "Authorization" : `Bearer ${tokenStr}`
          },
        };
        axios.post(url, formData, config).then((response) => {
          console.log(response.data);
          UpdateAll();
        });
    }
    function Deleted(row) {
        confirmAlert({
            title: 'Ви впевнені що хочите видалити цей пункт ?',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Так',
                    onClick: () => {
                    const response = axios.delete(process.env.REACT_APP_SERVER_URL + '/api/media_objects/' + row.id, header).then((response) => {
                      UpdateAll();
                    });
                    }
                },
                {
                    label: 'Ні',
                    onClick: () => {
                    return;
                    }
                }
            ]
        });
    }
    const columns = [
        { 
            field: 'image',
            headerName: 'Image',
            width: 100,
            
            editable: true,
            renderCell: (params) => <img src={process.env.REACT_APP_SERVER_URL + '' + params.row.contentUrl} style={{height:'45px', width:'auto'}} />
        },
        { 
            field: 'contentUrl', 
            headerName: 'Посилання', 
            width: 800,
            renderCell: (params) => {
                return process.env.REACT_APP_SERVER_URL + params.row.contentUrl;
            }
        },
        {
          field: "action_copy",
          headerName: "Copy",
          width: 90,
          sortable: false,
          renderCell: (params) => {
            // console.log(params);
            return <CButton type="submit" color="dark" onClick={() => {
                navigator.clipboard.writeText(process.env.REACT_APP_SERVER_URL + params.row.contentUrl)
            }
            }><CIcon icon={cilCopy} customClassName="nav-icon" height={20} /></CButton>;
          }
        },{
          field: "action",
          headerName: "Delete",
          width: 90,
          sortable: false,
          renderCell: (params) => {
            // console.log(params);
            return <CButton type="submit" color="danger" onClick={() => {
                Deleted(params.row)
              }
            }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
          }
        },
    ];
    return (
        <>
        <CCard className="mb-4">
            <CCardHeader>
              <strong>Завантажити зображення</strong> <small>загрузіть і збережіть</small>
            </CCardHeader>
            <CCardBody>
              <CForm onSubmit={handleSubmit}>
                <CRow>
                  <CCol xs={8}>
                    <input className="form-control form-control-lg" id="formFileLg" type="file"onChange={handleChange} />
                  </CCol>
                  <CCol xs={4}>
                    <CButton type="submit" color="warning">Завантажити зображення</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </CCardBody>
        </CCard>
        <CCard className="mb-4">
            <CCardHeader>
            <strong>Ваші медіа</strong> <small></small>
            </CCardHeader>
            <CCardBody>
                <div style={{ height: 800, width: '100%' }}>
                    <DataGrid
                       rows={rows}
                       columns={columns}
                       pageSize={30}
                       rowsPerPageOptions={[30]}
                       pagination
                       rowCount={rowCountState}
                       paginationMode="client"
                    />
                </div>
            </CCardBody>
        </CCard>
        </>
    );

}

export default Media;